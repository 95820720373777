import React from "react"
import imgPortadaGuide from "@components/pageGuide/guides/guide64/image/imgPortadaGuia.png"
import img1 from "@components/pageGuide/guides/guide64/image/img.png"
import slider1 from "@components/pageGuide/guides/guide64/image/silder1.png"
import slider2 from "@components/pageGuide/guides/guide64/image/silder2.png"
import slider3 from "@components/pageGuide/guides/guide64/image/silder3.png"

const title = (
  <>
    <h1 className="titles-underlined-ebook ebook-ventas">
    Cómo automatizar procesos de marketing para las Edtech 
    </h1>
  </>
)

const textDescription = (
  <div>
    <p className="text-h2-ebook guide__description">
    Automatizar los procesos de marketing en una Edtech es fundamental para mejorar la eficiencia y alcanzar mejores resultados. En este contenido, se explica cómo la automatización facilita la gestión de campañas, optimiza el uso de datos y permite personalizar cada interacción. 
    </p>
  </div>
)

const linkForm = (
  <span>
    Al registrarte, aceptas los términos y condiciones de nuestra{" "}
    <a
      style={{ color: "#2C5C82", textDecoration: "none" }}
      href="https://beexcc.com/politica-seguridad-privacidad.html"
    >
      Política de Privacidad.{" "}
    </a>
  </span>
)

const textOne = (
  <p>
Además, se exploran las herramientas y soluciones que puedes integrar en tu plataforma educativa para hacer más eficientes las estrategias de marketing digital.  
<br /><br />
Conoce cómo transformar la manera en que gestionas tus procesos y lograr un mayor impacto en tus campañas.
</p>
)

const data = {
  start: {
    support: "Guía | Educación",
    title: title,
    description: textDescription,
    classtext: "guide__description-container",
    link: linkForm,
    href: "/ebook-8.pdf",
    brochure: "Guía",
    img: imgPortadaGuide,
  },

  slider: {
    title: "¿Qué encontrarás?",
    underlined: "encontrarás?",
    checklist: [
      {
        key: 1,
        text: "Automatización de email para clientes potenciales.",
      },
      {
        key: 2,
        text: "Análisis de recorrido de los clientes potenciales.",
      },
      {
        key: 3,
        text: " Uso del lead scoring.",
      },
      {
        key: 4,
        text: " Personalización en la experiencia del alumno.",
      },
      {
        key: 5,
        text: "Uso de la API de WhatsApp Business.",
      },
    ],
    listPictures: [
      {
        key: 1,
        img: slider1,
      },
      {
        key: 2,
        img: slider2,
      },
      {
        key: 3,
        img: slider3,
      },
    ],
  },

  description: {
    cards: [
      {
        text: textOne,
        photo: img1,
        class: true,
      },
    ],
  },
}

export default data
